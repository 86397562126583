let english = [
  {
    "v": "5.6.5",
    "fr": 24,
    "ip": 0,
    "op": 96,
    "w": 4200,
    "h": 3238,
    "nm": "Freelancer Web Screens",
    "ddd": 0,
    "assets": [{
      "id": "image_0",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "img_0.png",
      "e": 0
    }, {
      "id": "image_1",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "img_1.png",
      "e": 0
    }, {
      "id": "image_2",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "img_2.png",
      "e": 0
    }],
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "Fre_Screen 3@2x.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 31.5,
            "s": [0]
          }, {
            "t": 48,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 31.5,
            "s": [2598.4, 1333.3, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 48,
            "s": [2598.4, 1133.3, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Fre_Screen 1@2x.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 17.75,
            "s": [0]
          }, {
            "t": 34.25,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 17.75,
            "s": [2100, 1819, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 34.25,
            "s": [2100, 1619, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Fre_Screen 2@2x.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 4,
            "s": [0]
          }, {
            "t": 20.5,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 4,
            "s": [1601.6, 2304.7, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 20.5,
            "s": [1601.6, 2104.7, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }],
    "markers": []
  },
  {
    "v": "5.6.5",
    "fr": 24,
    "ip": 0,
    "op": 96,
    "w": 4200,
    "h": 3238,
    "nm": "Employer Web Screens",
    "ddd": 0,
    "assets": [{
      "id": "image_0",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Emp_Screen_1_2x.png",
      "e": 0
    }, {
      "id": "image_1",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Emp_Screen_2_2x.png",
      "e": 0
    }, {
      "id": "image_2",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Emp_Screen_3_2x.png",
      "e": 0
    }],
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "Emp_Screen 1@2x.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 31.5,
            "s": [0]
          }, {
            "t": 48,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 31.5,
            "s": [2598.4, 1333.3, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 48,
            "s": [2598.4, 1133.3, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Emp_Screen 2@2x.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 18,
            "s": [0]
          }, {
            "t": 34.5,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 18,
            "s": [2100, 1819, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 34.5,
            "s": [2100, 1619, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Emp_Screen 3@2x.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 4,
            "s": [0]
          }, {
            "t": 20.5,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 4,
            "s": [1601.6, 2304.7, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 20.5,
            "s": [1601.6, 2104.7, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }],
    "markers": []
  }
  ];
let arabic = [
  {
    "v": "5.6.5",
    "fr": 24,
    "ip": 0,
    "op": 96,
    "w": 4200,
    "h": 3238,
    "nm": "Fre_Arabic",
    "ddd": 0,
    "assets": [{
      "id": "image_0",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Fre_Screen_Arabic_1.png",
      "e": 0
    }, {
      "id": "image_1",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Fre_Screen_Arabic_2.png",
      "e": 0
    }, {
      "id": "image_2",
      "w": 4576,
      "h": 3238,
      "u": "assets/images/sprites/",
      "p": "Fre_Screen_Arabic_3.png",
      "e": 0
    }],
    "layers": [{
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "Fre_Screen_Arabic 1.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 31.5,
            "s": [0]
          }, {
            "t": 48,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 31.5,
            "s": [2598.4, 1333.3, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 48,
            "s": [2598.4, 1133.3, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Fre_Screen_Arabic 2.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 18,
            "s": [0]
          }, {
            "t": 34.5,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 18,
            "s": [2100, 1819, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 34.5,
            "s": [2100, 1619, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Fre_Screen_Arabic 3.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [{
            "i": {
              "x": [0.337],
              "y": [1]
            },
            "o": {
              "x": [0.669],
              "y": [0]
            },
            "t": 4,
            "s": [0]
          }, {
            "t": 20.5,
            "s": [100]
          }],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [{
            "i": {
              "x": 0.337,
              "y": 1
            },
            "o": {
              "x": 0.669,
              "y": 0
            },
            "t": 4,
            "s": [1601.6, 2304.7, 0],
            "to": [0, -33.333, 0],
            "ti": [0, 33.333, 0]
          }, {
            "t": 20.5,
            "s": [1601.6, 2104.7, 0]
          }],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [2288, 1619, 0],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [70, 70, 100],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 96,
      "st": 0,
      "bm": 0
    }],
    "markers": []
  },
  {
  "v": "5.6.5",
  "fr": 24,
  "ip": 0,
  "op": 96,
  "w": 4200,
  "h": 3238,
  "nm": "Emp_Arabic",
  "ddd": 0,
  "assets": [{
    "id": "image_0",
    "w": 4576,
    "h": 3238,
    "u": "assets/images/sprites/",
    "p": "Emp_Screen_Arabic_1.png",
    "e": 0
  }, {
    "id": "image_1",
    "w": 4576,
    "h": 3238,
    "u": "assets/images/sprites/",
    "p": "Emp_Screen_Arabic_2.png",
    "e": 0
  }, {
    "id": "image_2",
    "w": 4576,
    "h": 3238,
    "u": "assets/images/sprites/",
    "p": "Emp_Screen_Arabic_3.png",
    "e": 0
  }],
  "layers": [{
    "ddd": 0,
    "ind": 1,
    "ty": 2,
    "nm": "Emp_Screen_Arabic 1.png",
    "cl": "png",
    "refId": "image_0",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {
            "x": [0.337],
            "y": [1]
          },
          "o": {
            "x": [0.669],
            "y": [0]
          },
          "t": 31.5,
          "s": [0]
        }, {
          "t": 48,
          "s": [100]
        }],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": {
            "x": 0.337,
            "y": 1
          },
          "o": {
            "x": 0.669,
            "y": 0
          },
          "t": 31.5,
          "s": [2598.4, 1333.3, 0],
          "to": [0, -33.333, 0],
          "ti": [0, 33.333, 0]
        }, {
          "t": 48,
          "s": [2598.4, 1133.3, 0]
        }],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [2288, 1619, 0],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [70, 70, 100],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 96,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 2,
    "ty": 2,
    "nm": "Emp_Screen_Arabic 2.png",
    "cl": "png",
    "refId": "image_1",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {
            "x": [0.337],
            "y": [1]
          },
          "o": {
            "x": [0.669],
            "y": [0]
          },
          "t": 18,
          "s": [0]
        }, {
          "t": 34.5,
          "s": [100]
        }],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": {
            "x": 0.337,
            "y": 1
          },
          "o": {
            "x": 0.669,
            "y": 0
          },
          "t": 18,
          "s": [2100, 1819, 0],
          "to": [0, -33.333, 0],
          "ti": [0, 33.333, 0]
        }, {
          "t": 34.5,
          "s": [2100, 1619, 0]
        }],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [2288, 1619, 0],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [70, 70, 100],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 96,
    "st": 0,
    "bm": 0
  }, {
    "ddd": 0,
    "ind": 3,
    "ty": 2,
    "nm": "Emp_Screen_Arabic 3.png",
    "cl": "png",
    "refId": "image_2",
    "sr": 1,
    "ks": {
      "o": {
        "a": 1,
        "k": [{
          "i": {
            "x": [0.337],
            "y": [1]
          },
          "o": {
            "x": [0.669],
            "y": [0]
          },
          "t": 4,
          "s": [0]
        }, {
          "t": 20.5,
          "s": [100]
        }],
        "ix": 11
      },
      "r": {
        "a": 0,
        "k": 0,
        "ix": 10
      },
      "p": {
        "a": 1,
        "k": [{
          "i": {
            "x": 0.337,
            "y": 1
          },
          "o": {
            "x": 0.669,
            "y": 0
          },
          "t": 4,
          "s": [1601.6, 2304.7, 0],
          "to": [0, -33.333, 0],
          "ti": [0, 33.333, 0]
        }, {
          "t": 20.5,
          "s": [1601.6, 2104.7, 0]
        }],
        "ix": 2
      },
      "a": {
        "a": 0,
        "k": [2288, 1619, 0],
        "ix": 1
      },
      "s": {
        "a": 0,
        "k": [70, 70, 100],
        "ix": 6
      }
    },
    "ao": 0,
    "ip": 0,
    "op": 96,
    "st": 0,
    "bm": 0
  }],
  "markers": []
}
];

export {
  english,
  arabic
}